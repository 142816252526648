import * as types from '../../constants'
import { SalariesTable } from 'types/salary'
import { Founder } from 'types/founder'
import { CalcBalances } from 'types/balance'
import FinanceOperation, { CountTypeEnum, OperationTypeEnum } from 'types/financeOperation'
import EmployeeProfitability from 'types/employeeProfitability'
import ArtAndDevInMonthRow from 'types/artAndDevInMonthRow'
import ProjectInMonthRow from 'types/projectsInMonthRow'
import Expenses from 'types/expense'

export type FinancesState = {
	loading: boolean

	artAndDevInMonth: ArtAndDevInMonthRow[]
	projectsInMonth: ProjectInMonthRow[]
	employeesProfitability: EmployeeProfitability[]
	generalExpenses: Expenses | null

	operations: FinanceOperation[]
	yearOperations: FinanceOperation[]
	balanceOperations: FinanceOperation[]
	advanceOperations: FinanceOperation[]
	dividends: FinanceOperation[]
	founders: Founder[]
	salariesTable: SalariesTable | null

	balances: CalcBalances

	hrBalance: number | null
	officeBalance: number | null
	otherBalance: number | null

	recruiterHrBalance: number | null
	recruiterOfficeBalance: number | null
	recruiterOtherBalance: number | null

	hrHrBalance: number | null
	hrOfficeBalance: number | null
	hrOtherBalance: number | null

	office_managerHrBalance: number | null
	office_managerOfficeBalance: number | null
	office_managerOtherBalance: number | null
}

const initialState: FinancesState = {
	loading: false,

	artAndDevInMonth: [],
	projectsInMonth: [],
	employeesProfitability: [],
	generalExpenses: null,
	operations: [],
	yearOperations: [],
	balanceOperations: [],
	advanceOperations: [],
	dividends: [],
	founders: [],
	salariesTable: null,

	balances: {
		general_dollar: 0,
		general_euro: 0,
		cash_dollar: 0,
		cash_euro: 0,
		ukraine_dollar: 0,
		ukraine_euro: 0,
		ukrainetwo_dollar: 0,
		ukrainetwo_euro: 0,
		lithuania_dollar: 0,
		lithuania_euro: 0,
		riga_dollar: 0,
		riga_euro: 0,
		unitedEmirates_dollar: 0,
		unitedEmirates_euro: 0,
		payoneerue_dollar: 0,
		payoneerue_euro: 0,
		marketing_dollar: 0,
		marketing_euro: 0
	},

	hrBalance: 0,
	officeBalance: 0,
	otherBalance: 0,

	recruiterHrBalance: 0,
	recruiterOfficeBalance: 0,
	recruiterOtherBalance: 0,

	hrHrBalance: 0,
	hrOfficeBalance: 0,
	hrOtherBalance: 0,

	office_managerHrBalance: 0,
	office_managerOfficeBalance: 0,
	office_managerOtherBalance: 0
}

export default function reducer(state = initialState, actions) {
	switch (actions.type) {
		case types.SET_DEFAULT_STATE:
			return initialState

		case types.LOAD_FINANCE_OPERATIONS_BY_MONTH_REQUEST:
		case types.LOAD_ADVANCE_OPERATIONS_BY_MONTH_REQUEST:
		case types.LOAD_FINANCE_OPERATIONS_BY_YEAR_REQUEST:
		case types.LOAD_BALANCE_BY_MONTH_REQUEST:
		case types.CREATE_INTER_BALANCE_TRANSACTION_REQUEST:
		case types.LOAD_DIVIDENDS_REQUEST:
		case types.LOAD_SALARIES_TABLE_REQUEST:
		case types.CREATE_FINANCE_OPERATION_REQUEST:
		case types.APPROVE_FINANCE_OPERATION_REQUEST:
		case types.REJECT_FINANCE_OPERATION_REQUEST:
		case types.CHECK_FINANCE_OPERATION_REQUEST:
		case types.DELETE_FINANCE_OPERATION_REQUEST:
		case types.UPLOAD_EXPENSE_FILE_REQUEST:
		case types.DOWNLOAD_EXPENSE_FILE_REQUEST:
		case types.DELETE_EXPENSE_FILE_REQUEST:
		case types.WITHDRAW_DIVIDENDS_REQUEST:
		case types.DISTRIBUTE_FOUNDERS_DIVIDENDS_REQUEST:
		case types.ADD_EMPLOYEE_BONUSES_REQUEST:
		case types.ADD_EMPLOYEE_FINES_REQUEST:
		case types.REMOVE_EMPLOYEE_BONUS_REQUEST:
		case types.REMOVE_EMPLOYEE_FINE_REQUEST:
		case types.CHECK_EMPLOYEE_SALARY_REQUEST:
		case types.ADD_NEW_EMPLOYEE_PAYMENT_REQUEST:
		case types.UPDATE_SALARIES_TABLE_STATUS_REQUEST:
		case types.SET_SALARIES_TABLE_DOLLAR_RATE_REQUEST:
		case types.WITHDRAW_SALARIES_TABLE_REQUEST:
		case types.UPDATE_SALARY_PAYMENT_TYPE_REQUEST:
		case types.FINANCE_IN_MONTH_RECALCULATE_REQUEST:
		case types.FINANCE_IN_MONTH_REQUEST:
			return {
				...state,
				loading: true
			}

		//success
		case types.FINANCE_IN_MONTH_RECALCULATE_SUCCESS:
		case types.FINANCE_IN_MONTH_SUCCESS:
			return {
				...state,
				loading: false,
				...actions.payload
			}

		case types.FINANCE_IN_MONTH_RECALCULATE_ERROR:
		case types.FINANCE_IN_MONTH_ERROR:
			return {
				...state,
				loading: false
			}

		case types.LOAD_FINANCE_OPERATIONS_BY_MONTH_SUCCESS:
			return {
				...state,
				loading: false,
				...actions.payload
			}

		case types.LOAD_FINANCE_OPERATIONS_BY_YEAR_SUCCESS:
			return {
				...state,
				loading: false,
				yearOperations: actions.payload
			}

		case types.CREATE_FINANCE_OPERATION_SUCCESS:
			const operation = actions.payload

			let updatedBalances = {}

			if (operation.type === OperationTypeEnum.other_expense) {
				const calculator = () => {
					switch (operation.count_type) {
						case CountTypeEnum.HR_EXPENSE:
							return {
								hrBalance: operation.count_balance_after,
								[`${operation.responsible_position}HrBalance`]:
									operation.position_balance_after
							}

						case CountTypeEnum.OFFICE_EXPENSE:
							return {
								officeBalance: operation.count_balance_after,
								[`${operation.responsible_position}OfficeBalance`]:
									operation.position_balance_after
							}

						case CountTypeEnum.OTHER_EXPENSE:
							return {
								otherBalance: operation.count_balance_after,
								[`${operation.responsible_position}OtherBalance`]:
									operation.position_balance_after
							}

						default:
							return {}
					}
				}

				updatedBalances = calculator()
			}

			return {
				...state,
				loading: false,
				operations: [operation, ...state.operations],
				...updatedBalances
			}

		case types.APPROVE_FINANCE_OPERATION_SUCCESS:
		case types.REJECT_FINANCE_OPERATION_SUCCESS:
			return {
				...state,
				loading: false,
				balanceOperations: state.balanceOperations.map((operation: FinanceOperation) => {
					if (operation._id === actions.payload._id) {
						return actions.payload
					}
					return operation
				})
			}

		case types.CHECK_FINANCE_OPERATION_SUCCESS:
			const newBalances = () => {
				switch (actions.payload.count_type) {
					case CountTypeEnum.HR_EXPENSE:
						return {
							hrBalance: actions.payload.count_balance_after,
							[`${actions.payload.responsible_position}HrBalance`]:
								actions.payload.position_balance_after
						}

					case CountTypeEnum.OFFICE_EXPENSE:
						return {
							officeBalance: actions.payload.count_balance_after,
							[`${actions.payload.responsible_position}OfficeBalance`]:
								actions.payload.position_balance_after
						}

					case CountTypeEnum.OTHER_EXPENSE:
						return {
							otherBalance: actions.payload.count_balance_after,
							[`${actions.payload.responsible_position}OtherBalance`]:
								actions.payload.position_balance_after
						}

					default:
						return {}
				}
			}

			return {
				...state,
				loading: false,
				operations: state.operations.map((operation: FinanceOperation) => {
					if (operation._id === actions.payload._id) {
						return actions.payload
					}
					return operation
				}),
				...newBalances()
			}

		case types.DELETE_FINANCE_OPERATION_SUCCESS:
			let balancesAfterDeleting = {}

			if (actions.payload.type === OperationTypeEnum.other_expense) {
				const calculator = () => {
					switch (actions.payload.count_type) {
						case CountTypeEnum.HR_EXPENSE:
							return {
								hrBalance: state.hrBalance + actions.payload.summ,
								[`${actions.payload.responsible_position}HrBalance`]:
									state[`${actions.payload.responsible_position}HrBalance`] +
									actions.payload.summ
							}

						case CountTypeEnum.OFFICE_EXPENSE:
							return {
								officeBalance: state.officeBalance + actions.payload.summ,
								[`${actions.payload.responsible_position}OfficeBalance`]:
									state[`${actions.payload.responsible_position}OfficeBalance`] +
									actions.payload.summ
							}

						case CountTypeEnum.OTHER_EXPENSE:
							return {
								otherBalance: state.otherBalance + actions.payload.summ,
								[`${actions.payload.responsible_position}OtherBalance`]:
									state[`${actions.payload.responsible_position}OtherBalance`] +
									actions.payload.summ
							}

						default:
							return {}
					}
				}

				balancesAfterDeleting = calculator()
			}

			return {
				...state,
				loading: false,
				operations: state.operations.filter(
					(operation: FinanceOperation) => operation._id !== actions.payload._id
				),
				...balancesAfterDeleting
			}

		//expense file

		case types.UPLOAD_EXPENSE_FILE_SUCCESS:
			return {
				...state,
				loading: false,
				operations: state.operations.map((operation: FinanceOperation) => {
					if (operation._id === actions.payload._id) {
						return actions.payload
					}
					return operation
				})
			}

		case types.DOWNLOAD_EXPENSE_FILE_SUCCESS:
			return {
				...state,
				loading: false
			}

		case types.DELETE_EXPENSE_FILE_SUCCESS:
			return {
				...state,
				loading: false,
				operations: state.operations.map((operation: FinanceOperation) => {
					if (operation._id === actions.payload._id) {
						return actions.payload
					}
					return operation
				})
			}

		//Balance
		//Dividends

		case types.LOAD_BALANCE_BY_MONTH_SUCCESS:
		case types.LOAD_DIVIDENDS_SUCCESS:
			return {
				...state,
				loading: false,
				...actions.payload
			}

		case types.CREATE_INTER_BALANCE_TRANSACTION_SUCCESS:
			return {
				...state,
				loading: false,
				balances: actions.payload.balances,
				balanceOperations: [
					...state.balanceOperations,
					...actions.payload.balanceOperations
				]
			}

		case types.DISTRIBUTE_FOUNDERS_DIVIDENDS_SUCCESS:
			return {
				...state,
				loading: false,
				founders: actions.payload
			}

		case types.WITHDRAW_DIVIDENDS_SUCCESS:
			return {
				...state,
				loading: false,
				balances: actions.payload.balances ?? state.balances,
				dividends: [...state.dividends, ...actions.payload.dividends],
				founders: state.founders.map((founder: Founder) => {
					if (founder._id === actions.payload.founders[0]._id) {
						return actions.payload.founders[0]
					}
					return founder
				})
			}

		//Advance operations
		case types.LOAD_ADVANCE_OPERATIONS_BY_MONTH_SUCCESS:
			return {
				...state,
				loading: false,
				advanceOperations: actions.payload
			}

		//Salaries table
		case types.LOAD_SALARIES_TABLE_SUCCESS:
		case types.REMOVE_EMPLOYEE_BONUS_SUCCESS:
		case types.REMOVE_EMPLOYEE_FINE_SUCCESS:
		case types.ADD_NEW_EMPLOYEE_PAYMENT_SUCCESS:
		case types.ADD_EMPLOYEE_BONUSES_SUCCESS:
		case types.ADD_EMPLOYEE_FINES_SUCCESS:
		case types.CHECK_EMPLOYEE_SALARY_SUCCESS:
		case types.UPDATE_SALARIES_TABLE_STATUS_SUCCESS:
		case types.UPDATE_SALARY_PAYMENT_TYPE_SUCCESS:
		case types.SET_SALARIES_TABLE_DOLLAR_RATE_SUCCESS:
		case types.WITHDRAW_SALARIES_TABLE_SUCCESS:
			return {
				...state,
				loading: false,
				salariesTable: actions.payload
			}

		case types.LOAD_FINANCE_OPERATIONS_BY_MONTH_ERROR:
		case types.LOAD_ADVANCE_OPERATIONS_BY_MONTH_ERROR:
		case types.LOAD_FINANCE_OPERATIONS_BY_YEAR_ERROR:
		case types.CREATE_FINANCE_OPERATION_ERROR:
		case types.APPROVE_FINANCE_OPERATION_ERROR:
		case types.REJECT_FINANCE_OPERATION_ERROR:
		case types.CHECK_FINANCE_OPERATION_ERROR:
		case types.DELETE_FINANCE_OPERATION_ERROR:
		case types.UPLOAD_EXPENSE_FILE_ERROR:
		case types.DOWNLOAD_EXPENSE_FILE_ERROR:
		case types.DELETE_EXPENSE_FILE_ERROR:
		case types.LOAD_BALANCE_BY_MONTH_ERROR:
		case types.CREATE_INTER_BALANCE_TRANSACTION_ERROR:
		case types.LOAD_DIVIDENDS_ERROR:
		case types.DISTRIBUTE_FOUNDERS_DIVIDENDS_ERROR:
		case types.WITHDRAW_DIVIDENDS_ERROR:
		case types.LOAD_SALARIES_TABLE_ERROR:
		case types.REMOVE_EMPLOYEE_BONUS_ERROR:
		case types.REMOVE_EMPLOYEE_FINE_ERROR:
		case types.ADD_EMPLOYEE_BONUSES_ERROR:
		case types.ADD_EMPLOYEE_FINES_ERROR:
		case types.ADD_NEW_EMPLOYEE_PAYMENT_ERROR:
		case types.CHECK_EMPLOYEE_SALARY_ERROR:
		case types.UPDATE_SALARIES_TABLE_STATUS_ERROR:
		case types.UPDATE_SALARY_PAYMENT_TYPE_ERROR:
		case types.SET_SALARIES_TABLE_DOLLAR_RATE_ERROR:
		case types.WITHDRAW_SALARIES_TABLE_ERROR:
		case types.LOAD_SYSTEM_SETTINGS_ERROR:
		case types.UPDATE_SYSTEM_SETTINGS_ERROR:
			return {
				...state,
				loading: false
			}

		default:
			return state
	}
}
