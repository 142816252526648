import async from '../components/Async'

import {
	Users as UsersIcon,
	PieChart as PieChartIcon,
	Inbox as InboxIcon,
	Book as BookIcon,
	Calendar as CalendarIcon,
	Home as HomeIcon,
	Coffee as CoffeeIcon,
	//Clipboard as ClipboardIcon,
	DollarSign as DollarSignIcon,
	Icon,
	UserCheck
} from 'react-feather'

// Misc
//import Blank from '../pages/misc/Blank'
import { RolesEnum } from 'types/roles'

const Children = async(() => import('../layouts/Children'))

// Auth
const SignIn = async(() => import('../pages/auth/SignIn'))
const SignUp = async(() => import('../pages/auth/SignUp'))
const ResetPassword = async(() => import('../pages/auth/ResetPassword'))
const Page404 = async(() => import('../pages/auth/Page404'))
const Page500 = async(() => import('../pages/auth/Page500'))

// Dashboards
const MyPage = async(() => import('../pages/dashboards/HR/Employees/MyPage'))
const PublisherPage = async(() => import('../pages/other/Publishers/PublisherPage'))
const EmployeePage = async(() => import('../pages/dashboards/HR/Employees/EmployeePage'))
const SurveyPage = async(() => import('../pages/other/Survey'))
const ConceptPage = async(() => import('../pages/dashboards/Concepts/ConceptPage'))
const ProjectPage = async(() => import('../pages/dashboards/Projects/ProjectPage'))
const SurveyGraphs = async(() => import('../pages/other/SurveyTable/SurveyGraphs'))

const Projects = async(() => import('../pages/dashboards/Projects'))
const GanttChart = async(() => import('../pages/dashboards/GanttChart'))
const EmployeesStatistic = async(() => import('../pages/dashboards/Statistic/EmployeesStatistic'))
const Developers = async(() => import('../pages/dashboards/ArtistsAndDevelopers/Developers'))
const Artists = async(() => import('../pages/dashboards/ArtistsAndDevelopers/Artists'))
const LevelDesigners = async(
	() => import('../pages/dashboards/ArtistsAndDevelopers/LevelDesigners')
)
const OtherPositions = async(
	() => import('../pages/dashboards/ArtistsAndDevelopers/OtherPositions')
)
const Ideation = async(() => import('../pages/dashboards/Ideation'))
const CompanyStatistics = async(() => import('../pages/dashboards/Statistic/CompanyStatistic'))
const MentorsStatistic = async(() => import('../pages/dashboards/Statistic/MentorsStatistic'))

const StartDayTimeTracking = async(() => import('pages/dashboards/HR/TimeTracking/TimeTracking'))
const Publishers = async(() => import('../pages/other/Publishers'))
//const SurveyTable = async(() => import('../pages/other/SurveyTable'))

//Recruiting
//const Applications = async(() => import('../pages/dashboards/Recruitment/Applications'))
//const Candidates = async(() => import('../pages/dashboards/Recruitment/Candidates'))
//const Vacancies = async(() => import('../pages/dashboards/Recruitment/Vacancies'))
//const VacancyPage = async(() => import('../pages/dashboards/Recruitment/Vacancies/VacancyPage'))
//const VacancyFunnelPage = async(
//	() => import('../pages/dashboards/Recruitment/Vacancies/VacancyFunnelPage')
//)

const Employees = async(() => import('../pages/dashboards/HR/Employees'))
const Vacation = async(() => import('../pages/dashboards/HR/Vacation'))
const Sicknesses = async(() => import('../pages/dashboards/HR/Sicknesses'))
const Parties = async(() => import('../pages/dashboards/HR/Parties'))
const TimeTrackingPage = async(() => import('../pages/dashboards/HR/TimeTracking'))
const Todolists = async(() => import('../pages/dashboards/HR/Todolists'))
const KPI = async(() => import('../pages/dashboards/HR/KPI'))

const Balance = async(() => import('../pages/dashboards/Finance/Balance'))
const Invoices = async(() => import('../pages/dashboards/Finance/Invoices'))
const Expenses = async(() => import('../pages/dashboards/Finance/Expenses'))
const Payback = async(() => import('../pages/dashboards/Finance/Payback'))
const Dividends = async(() => import('../pages/dashboards/Finance/Dividends'))
const Salaries = async(() => import('../pages/dashboards/Finance/Salaries'))
const Subscriptions = async(() => import('../pages/dashboards/Finance/Subscriptions'))

const SystemSettings = async(() => import('../pages/dashboards/SystemSettings'))

export type RouteItem = {
	path: string
	external_link?: boolean
	name: string
	icon?: Icon
	containsHome?: boolean
	component?: any
	isPrivate?: boolean
	badgeColor?: string
	badgeText?: string
	requiredRoles?: RolesEnum[]
	children?: RouteItem[]
	noRender?: boolean
}

// Routes
const routes: RouteItem[] = [
	{
		path: '/',
		name: 'sideMenu_mainLabel',
		icon: HomeIcon,
		component: MyPage
	},
	{
		path: 'projects',
		name: 'sideMenu_projectsLabel',
		icon: InboxIcon,
		component: Projects,
		requiredRoles: [RolesEnum.base]
	},
	{
		path: 'ganttChart',
		name: 'sideMenu_gantChart',
		icon: CalendarIcon,
		component: GanttChart,
		requiredRoles: [
			RolesEnum.management,
			RolesEnum.qa,
			RolesEnum.hr,
			RolesEnum.gantt_chart,
			RolesEnum.producer_asistant,
			RolesEnum.lead_level_designer,
			RolesEnum.art_lead
		]
	},
	{
		path: 'https://recruiting-system-24play.web.app',
		external_link: true,
		name: 'sideMenu_recruiting',
		icon: UserCheck,
		requiredRoles: [
			RolesEnum.hr,
			RolesEnum.recruiting,
			RolesEnum.management,
			RolesEnum.tech_lead
		]
	},
	/* {
		path: '/dashboard/recruiting',
		name: 'sideMenu_recruiting',
		icon: UserCheck,
		containsHome: true,
		requiredRoles: [
			RolesEnum.hr,
			RolesEnum.recruiting,
			RolesEnum.management,
			RolesEnum.art_lead,
			RolesEnum.tech_lead
		],
		children: [
			{
				path: '/dashboard/recruiting/applications',
				name: 'sideMenu_applications',
				component: Applications,
				isPrivate: true,
				requiredRoles: [
					RolesEnum.hr,
					RolesEnum.recruiting,
					RolesEnum.management,
					RolesEnum.art_lead,
					RolesEnum.tech_lead
				]
			},
			{
				path: '/dashboard/recruiting/vacancies',
				name: 'sideMenu_vacancies',
				component: Vacancies,
				isPrivate: true,
				requiredRoles: [RolesEnum.hr, RolesEnum.recruiting, RolesEnum.management]
			},
			{
				path: '/dashboard/recruiting',
				name: 'sideMenu_candidates',
				component: Candidates,
				isPrivate: true,
				requiredRoles: [RolesEnum.hr, RolesEnum.recruiting, RolesEnum.management]
			}
		]
	}, */
	{
		path: 'HR',
		name: 'sideMenu_HR',
		icon: UsersIcon,
		containsHome: true,
		isPrivate: true,
		requiredRoles: [
			RolesEnum.hr,
			RolesEnum.management,
			RolesEnum.art_lead,
			RolesEnum.co_lead,
			RolesEnum.lead_level_designer,
			RolesEnum.producer_asistant
		],
		component: Children,
		children: [
			{
				path: '/',
				name: 'sideMenu_employees',
				component: Employees,
				isPrivate: true,
				requiredRoles: [RolesEnum.project_manager, RolesEnum.hr, RolesEnum.employees]
			},
			{
				path: 'vacations',
				name: 'sideMenu_vacations',
				component: Vacation,
				isPrivate: true,
				requiredRoles: [RolesEnum.hr]
			},
			{
				path: 'sicknesses',
				name: 'sideMenu_sicknesses',
				component: Sicknesses,
				isPrivate: true,
				requiredRoles: [RolesEnum.hr]
			},
			{
				path: 'timeTracking',
				name: 'sideMenu_latenesses',
				component: TimeTrackingPage,
				isPrivate: true,
				requiredRoles: [RolesEnum.hr]
			},
			{
				path: 'parties',
				name: 'sideMenu_parties',
				component: Parties,
				isPrivate: true,
				requiredRoles: [RolesEnum.hr]
			},
			{
				path: 'todolists',
				name: 'sideMenu_todolists',
				component: Todolists,
				isPrivate: true,
				requiredRoles: [
					RolesEnum.hr,
					RolesEnum.management,
					RolesEnum.art_lead,
					RolesEnum.co_lead,
					RolesEnum.lead_level_designer,
					RolesEnum.producer_asistant
				]
			},
			{
				path: 'KPI',
				name: 'sideMenu_kpi',
				component: KPI,
				isPrivate: true,
				requiredRoles: [RolesEnum.admin]
			}
		]
	},
	{
		path: 'statistic',
		name: 'sideMenu_statistics',
		icon: PieChartIcon,
		containsHome: true,
		isPrivate: true,
		requiredRoles: [RolesEnum.management, RolesEnum.hr, RolesEnum.ratings],
		component: Children,
		children: [
			{
				path: '/',
				name: 'sideMenu_companyStatistic',
				component: CompanyStatistics,
				isPrivate: true,
				requiredRoles: [RolesEnum.admin]
			},
			{
				path: 'employees',
				name: 'sideMenu_employeesStatistic',
				component: EmployeesStatistic,
				isPrivate: true,
				requiredRoles: [RolesEnum.management, RolesEnum.hr, RolesEnum.ratings]
			},
			{
				path: 'mentors',
				name: 'sideMenu_mentorsStatistic',
				component: MentorsStatistic,
				isPrivate: true,
				requiredRoles: [RolesEnum.management, RolesEnum.hr, RolesEnum.ratings]
			}
		]
	},
	{
		path: 'employees',
		name: 'sideMenu_artAndDev',
		icon: CoffeeIcon,
		containsHome: true,
		isPrivate: true,
		requiredRoles: [
			RolesEnum.tech_lead,
			RolesEnum.lead_level_designer,
			RolesEnum.project_manager,
			RolesEnum.hr,
			RolesEnum.developers
		],
		component: Children,
		children: [
			{
				path: '/',
				name: 'sideMenu_developers',
				component: Developers,
				isPrivate: true,
				requiredRoles: [
					RolesEnum.tech_lead,
					RolesEnum.project_manager,
					RolesEnum.hr,
					RolesEnum.developers
				]
			},
			{
				path: 'artists',
				name: 'sideMenu_artists',
				component: Artists,
				isPrivate: true,
				requiredRoles: [RolesEnum.art_lead, RolesEnum.project_manager, RolesEnum.hr]
			},
			{
				path: 'level_designers',
				name: 'sideMenu_levelDesigners',
				component: LevelDesigners,
				isPrivate: true,
				requiredRoles: [
					RolesEnum.lead_level_designer,
					RolesEnum.project_manager,
					RolesEnum.hr
				]
			},
			{
				path: 'other_positions',
				name: 'sideMenu_otherPositions',
				component: OtherPositions,
				isPrivate: true,
				requiredRoles: [RolesEnum.hr]
			},
			{
				path: 'ideation',
				name: 'sideMenu_ideation',
				component: Ideation,
				isPrivate: true,
				requiredRoles: [
					RolesEnum.tech_lead,
					RolesEnum.art_lead,
					RolesEnum.project_manager,
					RolesEnum.hr,
					RolesEnum.developers
				]
			}
		]
	},
	{
		path: 'publishers',
		name: 'sideMenu_publishers',
		icon: BookIcon,
		component: Publishers,
		isPrivate: true,
		requiredRoles: [RolesEnum.project_manager, RolesEnum.publishers]
	},
	/* {
		path: '/other/survey',
		name: 'sideMenu_surveys',
		icon: ClipboardIcon,
		containsHome: true,
		isPrivate: true,
		requiredRoles: [RolesEnum.management, RolesEnum.hr, RolesEnum.survey],
		children: [
			{
				path: '/other/survey',
				name: 'sideMenu_allSurveys',
				component: SurveyTable,
				isPrivate: true,
				requiredRoles: [RolesEnum.management, RolesEnum.hr, RolesEnum.survey]
			},
			{
				path: '/other/survey/company',
				name: 'sideMenu_companyStatistics',
				component: SurveyGraphs,
				isPrivate: true,
				requiredRoles: [RolesEnum.management, RolesEnum.hr, RolesEnum.survey]
			}
		]
	}, */
	{
		path: 'finances',
		name: 'sideMenu_finances',
		icon: DollarSignIcon,
		containsHome: true,
		isPrivate: true,
		component: Children,
		requiredRoles: [
			RolesEnum.admin,
			RolesEnum.assistant_manager,
			RolesEnum.finances,
			RolesEnum.hr,
			RolesEnum.invoices,
			RolesEnum.expenses,
			RolesEnum.subscriptions,
			RolesEnum.balances
		],
		children: [
			{
				path: '/',
				name: 'sideMenu_invoices',
				component: Invoices,
				isPrivate: true,
				requiredRoles: [RolesEnum.admin, RolesEnum.project_manager, RolesEnum.invoices]
			},
			{
				path: 'balance',
				name: 'sideMenu_balance',
				component: Balance,
				isPrivate: true,
				requiredRoles: [RolesEnum.admin, RolesEnum.assistant_manager, RolesEnum.balances]
			},
			{
				path: 'expenses',
				name: 'sideMenu_expenses',
				component: Expenses,
				isPrivate: true,
				requiredRoles: [
					RolesEnum.admin,
					RolesEnum.assistant_manager,
					RolesEnum.hr,
					RolesEnum.expenses
				]
			},
			{
				path: 'salaries',
				name: 'sideMenu_salaries',
				component: Salaries,
				isPrivate: true,
				requiredRoles: [RolesEnum.salary, RolesEnum.hr]
			},
			{
				path: 'profitability',
				name: 'sideMenu_payback',
				component: Payback,
				isPrivate: true,
				requiredRoles: [RolesEnum.admin, RolesEnum.finances]
			},
			{
				path: 'dividends',
				name: 'sideMenu_dividends',
				component: Dividends,
				isPrivate: true,
				requiredRoles: [RolesEnum.admin, RolesEnum.founder]
			},
			{
				path: 'subscriptions',
				name: 'sideMenu_subscriptions',
				component: Subscriptions,
				isPrivate: true,
				requiredRoles: [
					RolesEnum.admin,
					RolesEnum.assistant_manager,
					RolesEnum.hr,
					RolesEnum.subscriptions
				]
			}
		]
	}
]

const authRoutes: RouteItem = {
	path: '/auth',
	name: 'Auth',
	icon: UsersIcon,
	badgeColor: 'secondary',
	badgeText: 'Special',
	children: [
		{
			path: '/auth/sign-in',
			name: 'Sign In',
			component: SignIn
		},
		{
			path: '/auth/sign-up',
			name: 'Sign Up',
			component: SignUp
		},
		{
			path: '/auth/reset-password',
			name: 'Reset Password',
			component: ResetPassword
		},
		{
			path: '/auth/404',
			name: '404 Page',
			component: Page404
		},
		{
			path: '/auth/500',
			name: '500 Page',
			component: Page500
		}
	]
}

// This route is not visisble in the sidebar
const privateRoutes: RouteItem[] = [
	{
		path: 'concept/:id',
		name: 'privateRoutes_сonceptPage',
		component: ConceptPage,
		isPrivate: true
	},
	{
		path: 'project/:id',
		name: 'privateRoutes_projectPage',
		component: ProjectPage,
		isPrivate: true
	},
	/* {
			path: '/vacancy/:id',
			name: 'privateRoutes_vacancyPage',
			component: VacancyPage,
			isPrivate: true
		},
		{
			path: '/vacancy_funnels/:id',
			name: 'privateRoutes_vacancyFunnelPage',
			component: VacancyFunnelPage,
			isPrivate: true
		}, */
	{
		path: 'employee/:id',
		name: 'privateRoutes_еmployeePage',
		component: EmployeePage,
		isPrivate: true
	},
	{
		path: 'publisher/:id',
		name: 'privateRoutes_publisherPage',
		component: PublisherPage,
		isPrivate: true
	},
	{
		path: 'checkin',
		name: 'Checkin',
		component: StartDayTimeTracking,
		isPrivate: true
	},
	{
		path: 'survey',
		name: 'privateRoutes_survey',
		component: SurveyPage,
		isPrivate: true
	},
	{
		path: 'system_settings',
		name: 'privateRoutes_adminPanel',
		component: SystemSettings,
		isPrivate: true
	},
	{
		path: 'survey/:id',
		name: 'privateRoutes_surveyGraphs',
		component: SurveyGraphs,
		isPrivate: true
	}
]

// Dashboard specific routes
export const dashboard = [...routes, ...privateRoutes]

// Auth specific routes
export const page = [authRoutes]

// All routes
export default routes
