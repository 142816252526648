import React, { lazy } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { createBrowserRouter, redirect, RouteObject, RouterProvider } from 'react-router-dom'
import { dashboard as dashboardRoutes, RouteItem } from './index'
import { Helmet } from 'react-helmet'

import Loader from 'components/Loader'
import operations from 'redux/operations'

import RolesGuard from './RolesGuard'

const DashboardLayout = lazy(() => import('../layouts/Dashboard'))
const SignIn = lazy(() => import('../pages/auth/SignIn'))
const Page404 = lazy(() => import('pages/auth/Page404'))
const LoginErrorPage = lazy(() => import('pages/auth/LoginErrorPage'))

const ChildRoutes = () => {
	const { t, i18n } = useTranslation()
	return dashboardRoutes.reduce((acc, routeItem: RouteItem) => {
		if (routeItem.path === '/') {
			acc.push({
				index: true,
				element: (
					<React.Suspense fallback={<Loader />}>
						<RolesGuard routeItem={routeItem}>
							<Helmet>
								<title>
									{i18n.exists(routeItem.name)
										? t(routeItem.name)
										: routeItem.name}
								</title>
							</Helmet>
							{routeItem.component ? <routeItem.component /> : null}
						</RolesGuard>
					</React.Suspense>
				)
			})
		} else {
			acc.push({
				path: routeItem.path,
				element: (
					<React.Suspense fallback={<Loader />}>
						<RolesGuard routeItem={routeItem}>
							<Helmet>
								<title>
									{i18n.exists(routeItem.name)
										? t(routeItem.name)
										: routeItem.name}
								</title>
							</Helmet>
							{routeItem.component ? <routeItem.component /> : null}
						</RolesGuard>
					</React.Suspense>
				),
				children: routeItem.children
					? routeItem.children.map((child: RouteItem) =>
							child.path === '/'
								? {
										index: true,
										element: (
											<React.Suspense fallback={<Loader />}>
												<RolesGuard routeItem={routeItem}>
													<Helmet>
														<title>
															{i18n.exists(child.name)
																? t(child.name)
																: child.name}
														</title>
													</Helmet>
													{child.component ? <child.component /> : null}
												</RolesGuard>
											</React.Suspense>
										)
								  }
								: {
										path: child.path,
										element: (
											<React.Suspense fallback={<Loader />}>
												<RolesGuard routeItem={routeItem}>
													<Helmet>
														<title>
															{i18n.exists(child.name)
																? t(child.name)
																: child.name}
														</title>
													</Helmet>
													{child.component ? <child.component /> : null}
												</RolesGuard>
											</React.Suspense>
										)
								  }
					  )
					: undefined
			})
		}

		return acc
	}, [] as RouteObject[])
}

const Routes = () => {
	const dispatch = useDispatch()

	return (
		<RouterProvider
			router={createBrowserRouter([
				{
					path: '/',
					loader: async () => {
						const user = await dispatch(operations.userOperations.auth.fetchUserFunc())

						if (!user) {
							console.log('nef')
							return redirect('/sign_in')
						}

						await dispatch(
							operations.dataOperations.notifications.loadEmployeeNotificationsFunc()
						)

						return user
					},
					element: <DashboardLayout />,
					errorElement: <Page404 />,
					children: ChildRoutes()
				},
				{
					path: '/sign_in',
					loader: async () => {
						const user = await dispatch(operations.userOperations.auth.fetchUserFunc())

						if (!user) {
							return true
						}

						return redirect('/')
					},
					element: <SignIn />
				},
				{
					path: '/login_error',
					element: <LoginErrorPage />
				}
			])}
			fallbackElement={<Loader />}
		/>
	)
}

export default Routes
