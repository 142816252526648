import { MonthsOptions } from 'components/ProjectsByMonthsSelector'
import { ShortConcept } from './concept'
import { ShortEmployee } from './employee'
import { PlatformEnum } from './enums'
import { CurrencyType } from './invoice'
import ProjectArtInfo from './project_art_info'
import { ShortPublisher } from './publisher'
import { NoSurvey, ProjectSurvey, SurveyState } from './survey'
import Visits from './visitCheck'
import SelectOption from './selectOption'

export enum DevStateEnum {
	setup = 'setup',
	paused = 'paused',
	tech_setup = 'tech_setup',
	mvp_dev = 'mvp_dev',
	mvp_done = 'mvp_done',
	in_testing = 'in_testing',
	tested = 'tested'
}

export enum ProjectTypeEnum {
	prototype = 'prototype',
	playable = 'playable',
	ctr = 'ctr',
	subtask = 'subtask'
}

export interface ProjectCount {
	_id: string
	project_count: number
}

export interface WorkPeriod {
	_id: string
	start_work: string
	end_work: string | null
}

export interface ProjectTeamMember {
	_id: string
	employee: ShortEmployee
	work_periods: WorkPeriod[]
}

export enum ProjectRolesEnum {
	producer = 'producer',

	lead = 'lead',
	co_lead = 'co_lead',
	art_lead = 'art_lead',
	lead_level_designer = 'lead_level_designer',

	developer = 'developer',
	mentor = 'mentor',

	artist = 'artist',
	art_mentor = 'art_mentor',

	concept_artist = 'concept_artist',
	qa = 'qa',
	game_designer = 'game_designer',
	level_designer = 'level_designer',
	economy_designer = 'economy_designer',
	sound_designer = 'sound_designer',
	motion_designer = 'motion_designer',
	animator = 'animator'
}

export interface ReleasedProject {
	project: Project
	survey: SurveyState | NoSurvey
}

export interface ProjectWithIteration {
	previousProjectId?: string
	conceptId?: string
	newProject: Project
}

export interface ProjectCreationData {
	project_info: {
		name: string
		concept?: string
		parent_project?: string

		project_type: string
		platforms?: string[]
		burn_rate?: boolean

		publisher: string

		creating: Date
		plan_mvp: Date
		plan_release: Date

		gdd_link?: string
		tech_task_link?: string

		invoice_summ?: number
		currency?: CurrencyType

		iteration_number?: number
		priv_iteration?: string
	}
	members: MembersForCreate
}

export interface WorkPeriodForUpdate {
	_id: string | null
	start_work: string
	end_work: string | null
}

export interface ProjectTeamMemberForUpdate {
	_id: string | null
	employee: string
	work_periods: WorkPeriodForUpdate[]
}

export type MembersForCreate = {
	[key in ProjectRolesEnum]: string[]
}

export type MembersForUpdate = {
	[key in ProjectRolesEnum]: ProjectTeamMemberForUpdate[]
}

export type ProjectMembersObject = {
	[key in ProjectRolesEnum]: SelectOption[]
}

export type ProjectMemberMonitor = {
	[key in ProjectRolesEnum]: { new: string[]; deleted: string[] }
}

export interface ProjectUpdatingData {
	id: string
	name: string
	project_type: string
	platforms?: string[] | null

	publisher: string

	members: MembersForUpdate

	creating: Date
	plan_mvp: Date
	plan_release: Date
	mvp_done?: Date | null
	release_done?: Date | null

	gdd_link?: string
	roadmap_link?: string
	github_link?: string | null
	tech_task_link?: string
}

export default interface Project {
	_id: string

	name: string
	concept?: ShortConcept
	parent_project?: string
	project_type: ProjectTypeEnum
	platforms?: PlatformEnum[]

	roadmap_link?: string
	gdd_link?: string
	github_link?: string
	tech_task_link?: string
	cloud_build?: boolean

	quality_approve?: boolean
	dev_state: DevStateEnum
	paused_on?: DevStateEnum

	publisher: ShortPublisher

	producer?: ProjectTeamMember[]

	lead?: ProjectTeamMember[]
	art_lead?: ProjectTeamMember[]
	co_lead?: ProjectTeamMember[]
	lead_level_designer?: ProjectTeamMember[]

	developer?: ProjectTeamMember[]
	artist?: ProjectTeamMember[]
	concept_artist?: ProjectTeamMember[]
	mentor?: ProjectTeamMember[]
	art_mentor?: ProjectTeamMember[]
	qa?: ProjectTeamMember[]
	game_designer?: ProjectTeamMember[]
	level_designer?: ProjectTeamMember[]
	economy_designer?: ProjectTeamMember[]
	sound_designer?: ProjectTeamMember[]
	motion_designer?: ProjectTeamMember[]
	animator?: ProjectTeamMember[]

	art_info: ProjectArtInfo
	visits: Visits
	subtasks?: Project[]

	survey?: ProjectSurvey

	burn_rate?: boolean

	invoice_summ?: number
	currency?: CurrencyType

	dates: {
		creating: Date
		plan_mvp: Date
		plan_release: Date
		moved_plan_release?: Date
		tech_setup?: Date
		mvp_done?: Date
		release_done?: Date
		test_ended?: Date
		pay_received?: Date
		quality_approve?: Date
		pause?: Date
		continue?: Date
	}

	results: {
		failed_test?: boolean
		cpi?: number
		retention?: number
		ctr?: number
	}

	iteration_number: number
	next_iteration?: string
	priv_iteration?: string
}

export interface ProjectResultData {
	readonly project_id: string
	readonly project_type: ProjectTypeEnum
	readonly date: Date
	readonly failed_test: boolean
	readonly cpi: number
	readonly retention?: number
	readonly ctr?: number
}

export interface UpdatedProjectSummData {
	id: string
	invoice_summ: number
	currency: CurrencyType
}

export interface ShortProject {
	_id: string
	name: string
	project_type: ProjectTypeEnum
	iteration_number: number
}

export interface EmployeeProjectWithWorkDays {
	_id: string
	project: ShortProject

	mentor?: ShortEmployee
	mentor_bonus?: number
	art_mentor?: ShortEmployee
	art_mentor_bonus?: number

	work_days: number
	role: ProjectRolesEnum
	dev_share: number
	employee_project_cost: number
}

export interface InvoiceShortProject {
	_id: string
	name: string
	project_type: ProjectTypeEnum
	iteration_number: number
	dev_state: DevStateEnum
	publisher: ShortPublisher
}

export interface PauseProjectData {
	id: string
	dev_state: DevStateEnum
	date: Date
}

export interface DoneProjectsPayload {
	projects: Project[]
	months: MonthsOptions
}

export interface TechSetupData {
	id: string
	github_link: string
	roadmap_link: string
	cloudBuild: boolean
}

export interface ProjectEmployees {
	_id: string
	project: ShortProject
	employees: string[]
}
